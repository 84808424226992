import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    /* Main layout. Admin pages  */
    {
      path: '/',
      beforeEnter: (to, from, next) => {
        const userData = JSON.parse(localStorage.getItem('userData'))

        if (
          !userData
          || (userData.userRole !== 'admin' && userData.userRole !== 'partner')
        ) {
          next({ name: 'login' })
        } else {
          next({ name: 'home' })
        }
      },
    },
    {
      path: '/admin',
      component: () => import('@/layouts/admin/Admin.vue'),
      children: [
        {
          path: '/',
          name: 'home',
          component: () => import('@/views/Home.vue'),
          meta: {
            pageTitle: 'Home',
            breadcrumb: [
              {
                text: 'Home',
                active: true,
              },
            ],
          },
        },
        {
          path: '/emails/:page?',
          name: 'emails',
          props: true,
          component: () => import('@/views/emails/Emails.vue'),
          meta: {
            pageTitle: 'Emails',
            breadcrumb: [
              {
                text: 'Emails',
                active: true,
              },
            ],
          },
        },
        {
          path: '/email/:emailId',
          name: 'editEmail',
          props: true,
          component: () => import('@/views/emails/EmailEdit.vue'),
          meta: {
            pageTitle: 'Edit Email',
            breadcrumb: [
              {
                text: 'Emails',
                to: { name: 'emails' },
              },
              {
                text: 'Edit Email',
                active: true,
              },
            ],
          },
        },
        {
          path: '/email/view/:emailId',
          name: 'viewEmail',
          props: true,
          component: () => import('@/views/emails/EmailView.vue'),
          meta: {
            pageTitle: 'View Email',
            breadcrumb: [
              {
                text: 'Emails',
                to: { name: 'emails' },
              },
              {
                text: 'View Email',
                active: true,
              },
            ],
          },
        },

        {
          path: '/trips/:page?',
          name: 'trips',
          props: true,
          component: () => import('@/views/trips/Trips.vue'),
          meta: {
            pageTitle: 'Trips',
            breadcrumb: [
              {
                text: 'Trips',
                active: true,
              },
            ],
          },
        },
        {
          path: '/trip/:tripId',
          name: 'editTrip',
          props: true,
          component: () => import('@/views/trips/TripEdit.vue'),
          meta: {
            pageTitle: 'Edit Trip',
            breadcrumb: [
              {
                text: 'Trips',
                to: { name: 'trips' },
              },
              {
                text: 'Edit Trip',
                active: true,
              },
            ],
          },
        },
        {
          path: '/trip/view/:tripId',
          name: 'viewTrip',
          props: true,
          component: () => import('@/views/trips/TripView.vue'),
          meta: {
            pageTitle: 'View Trip',
            breadcrumb: [
              {
                text: 'Trips',
                to: { name: 'trips' },
              },
              {
                text: 'View Trip',
                active: true,
              },
            ],
          },
        },

        {
          path: '/users/:page?',
          name: 'users',
          component: () => import('@/views/users/Users.vue'),
          meta: {
            pageTitle: 'Users',
            breadcrumb: [
              {
                text: 'Users',
                active: true,
              },
            ],
          },
        },
        {
          path: '/user/:userId',
          name: 'editUser',
          props: true,
          component: () => import('@/views/users/UserEdit.vue'),
          meta: {
            pageTitle: 'Edit User',
            breadcrumb: [
              {
                text: 'Users',
                to: { name: 'users' },
              },
              {
                text: 'Edit User',
                active: true,
              },
            ],
          },
        },
        {
          path: '/user/view/:userId',
          name: 'viewUser',
          props: true,
          component: () => import('@/views/users/UserView.vue'),
          meta: {
            pageTitle: 'View User',
            breadcrumb: [
              {
                text: 'Users',
                to: { name: 'users' },
              },
              {
                text: 'View User',
                active: true,
              },
            ],
          },
        },
        {
          path: '/users-create',
          name: 'createUser',
          component: () => import('@/views/users/UserCreate.vue'),
          meta: {
            pageTitle: 'Create User',
            breadcrumb: [
              {
                text: 'Users',
                to: { name: 'users' },
              },
              {
                text: 'Create User',
                active: true,
              },
            ],
          },
        },

        {
          path: '/vendors/:page?',
          name: 'vendors',
          component: () => import('@/views/vendors/Vendors.vue'),
          meta: {
            pageTitle: 'Vendors',
            breadcrumb: [
              {
                text: 'Vendors',
                active: true,
              },
            ],
          },
        },
        {
          path: '/vendor/:vendorId',
          name: 'editVendor',
          props: true,
          component: () => import('@/views/vendors/VendorEdit.vue'),
          meta: {
            pageTitle: 'Edit Vendor',
            breadcrumb: [
              {
                text: 'Vendors',
                to: { name: 'vendors' },
              },
              {
                text: 'Edit Vendor',
                active: true,
              },
            ],
          },
        },
        {
          path: '/vendor/view/:userId',
          name: 'viewVendor',
          props: true,
          component: () => import('@/views/vendors/VendorView.vue'),
          meta: {
            pageTitle: 'View Vendor',
            breadcrumb: [
              {
                text: 'Vendors',
                to: { name: 'vendors' },
              },
              {
                text: 'View Vendor',
                active: true,
              },
            ],
          },
        },
        {
          path: '/vendors-create',
          name: 'createVendor',
          component: () => import('@/views/vendors/VendorCreate.vue'),
          meta: {
            pageTitle: 'Create Vendor',
            breadcrumb: [
              {
                text: 'Vendors',
                to: { name: 'vendors' },
              },
              {
                text: 'Create Vendor',
                active: true,
              },
            ],
          },
        },

        {
          path: '/custom-trips/:page?',
          name: 'custom-trips',
          component: () => import('@/views/custom-trips/CustomTrips.vue'),
          meta: {
            pageTitle: 'Custom Trips',
            breadcrumb: [
              {
                text: 'Custom Trips',
                active: true,
              },
            ],
          },
        },
        {
          path: '/trip-offer/:customOfferId',
          name: 'editOffer',
          props: true,
          component: () => import('@/views/trip-offers/TripOfferEdit.vue'),
          meta: {
            pageTitle: 'Edit Custom Offer',
            breadcrumb: [
              {
                text: 'Trip Offers',
                to: { name: 'trip-offer' },
              },
              {
                text: 'Edit Trip Offer',
                active: true,
              },
            ],
          },
        },
        {
          path: '/trip-offer/view/:customOfferId',
          name: 'viewCustomOffer',
          props: true,
          component: () => import('@/views/trip-offers/TripOfferView.vue'),
          meta: {
            pageTitle: 'View Custom Offer',
            breadcrumb: [
              {
                text: 'Trip Offers',
                to: { name: 'trip-offer' },
              },
              {
                text: 'View Trip Offer',
                active: true,
              },
            ],
          },
        },

        {
          path: '/domains/:page?',
          name: 'domains',
          component: () => import('@/views/domains/Domains.vue'),
          meta: {
            pageTitle: 'Domains',
            breadcrumb: [
              {
                text: 'Domains',
                active: true,
              },
            ],
          },
        },
        {
          path: '/domain/:domainId',
          name: 'editDomain',
          props: true,
          component: () => import('@/views/domains/DomainEdit.vue'),
          meta: {
            pageTitle: 'Edit Domain',
            breadcrumb: [
              {
                text: 'Domains',
                to: { name: 'domains' },
              },
              {
                text: 'Edit Domain',
                active: true,
              },
            ],
          },
        },
        {
          path: '/domain/view/:domainId',
          name: 'viewDomain',
          props: true,
          component: () => import('@/views/domains/DomainView.vue'),
          meta: {
            pageTitle: 'View Domain',
            breadcrumb: [
              {
                text: 'Domains',
                to: { name: 'domains' },
              },
              {
                text: 'View Domain',
                active: true,
              },
            ],
          },
        },
        {
          path: '/domains-create',
          name: 'createDomain',
          component: () => import('@/views/domains/DomainCreate.vue'),
          meta: {
            pageTitle: 'Create Domain',
            breadcrumb: [
              {
                text: 'Domains',
                to: { name: 'domains' },
              },
              {
                text: 'Create Domain',
                active: true,
              },
            ],
          },
        },
      ],
      beforeEnter: (to, from, next) => {
        const userData = JSON.parse(localStorage.getItem('userData'))

        if (
          !userData
          || (userData.userRole !== 'admin' && userData.userRole !== 'partner')
        ) {
          next({ name: 'login' })
        } else {
          next()
        }
      },
    },

    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },

    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },

    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
