/* eslint-disable no-underscore-dangle */
import { $themeBreakpoints } from '@themeConfig'
import axios from '@/libs/axios'

const userData = JSON.parse(localStorage.getItem('userData'))

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    alerts: [],
    vendors: [],
    vendor: '',
    users: [],
    user: '',
    emails: [],
    email: '',
    domains: [],
    domain: '',
    trips: [],
    trip: '',
    customTrips: [],
    customTrip: '',
    loggedUser: userData || {},
    availableUsers: [],
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
    getAlerts: state => state.alerts,
    getVendors: state => state.vendors,
    getVendor: state => state.vendor,
    getUsers: state => state.users,
    getUser: state => state.user,
    getEmails: state => state.emails,
    getEmail: state => state.email,
    getDomains: state => state.domains,
    getDomain: state => state.domain,
    getTrips: state => state.trips,
    getTrip: state => state.trip,
    getCustomTrips: state => state.customTrips,
    getLoggedUser: state => state.loggedUser,
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    ADD_ALERT(state, alert) {
      state.alerts.push(alert)
    },
    SET_VENDORS(state, vendors) {
      state.vendors = vendors
    },
    SET_CUSTOM_TRIPS(state, customTrips) {
      state.customTrips = customTrips
    },
    SET_CUSTOM_TRIP(state, customTrip) {
      state.customTrip = customTrip
    },
    SET_AVAILABLE_USERS(state, availableUsers) {
      state.availableUsers = availableUsers
    },
    SET_VENDOR(state, vendor) {
      state.vendor = vendor
    },
    SET_USERS(state, users) {
      state.users = users
    },
    SET_USER(state, user) {
      state.user = user
    },
    SET_EMAILS(state, emails) {
      state.emails = emails
    },
    SET_EMAIL(state, email) {
      state.email = email
    },
    SET_DOMAINS(state, domains) {
      state.domains = domains
    },
    SET_DOMAIN(state, domain) {
      state.domain = domain
    },
    SET_TRIPS(state, trips) {
      state.trips = trips
    },
    SET_TRIP(state, trip) {
      state.trip = trip
    },
    SET_LOGGED_USER(state, loggedUser) {
      state.loggedUser = loggedUser
    },
  },
  actions: {
    setLoggedUser({ commit }, user) {
      commit('SET_LOGGED_USER', user)
    },
    fetchVendors({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/vendors')
          .then(response => {
            commit('SET_VENDORS', response.data.vendors)

            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchVendor({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/vendors/${payload}`)
          .then(response => {
            commit(
              'SET_VENDOR',
              response.data && response.data.vendor
                ? response.data.vendor
                : null,
            )

            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteVendor({ commit, state }, vendor) {
      const oldVendorId = vendor._id

      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/vendors/${vendor._id}`)
          .then(response => {
            commit(
              'SET_VENDORS',
              state.vendors.filter(item => item._id !== oldVendorId),
            )

            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-empty-pattern
    saveVendor({}, form) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/api/vendors/${form._id}`, {
            name: form.name,
            subject: form.subject,
            email: form.email,
            status: form.status,
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-empty-pattern
    createVendor({}, form) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/vendors', {
            name: form.name,
            subject: form.subject,
            email: form.email,
            status: form.status,
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchUsers({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/users')
          .then(response => {
            commit('SET_USERS', response.data.users)

            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchUser({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/users/${payload}`)
          .then(response => {
            commit(
              'SET_USER',
              response.data && response.data.user ? response.data.user : null,
            )

            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteUser({ commit, state }, user) {
      const oldUserId = user._id

      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/users/${user._id}`)
          .then(response => {
            commit(
              'SET_USERS',
              state.users.filter(item => item._id !== oldUserId),
            )

            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-empty-pattern
    saveUser({}, form) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/api/users/${form._id}`, {
            name: form.name,
            surname: form.surname,
            email: form.email,
            role: form.role,
            status: form.status,
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-empty-pattern
    createUser({}, form) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/users', {
            name: form.name,
            surname: form.surname,
            email: form.email,
            role: form.role,
            password: form.password,
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchEmails({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/emails', {
            params: payload,
          })
          .then(response => {
            commit('SET_EMAILS', response.data)

            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchEmail({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/emails/${payload}`)
          .then(response => {
            commit('SET_EMAIL', response.data)

            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteEmail({ commit, state }, user) {
      const oldEmailId = user._id

      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/emails/${user._id}`)
          .then(response => {
            commit(
              'SET_EMAILS',
              state.emails.emails.filter(item => item._id !== oldEmailId),
            )

            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-empty-pattern
    saveEmail({}, form) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/api/emails/${form._id}`, {
            from: form.from,
            to: form.to,
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchDomains({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/domains')
          .then(response => {
            commit('SET_DOMAINS', response.data.domains)

            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchDomain({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/domains/${payload}`)
          .then(response => {
            commit('SET_DOMAIN', response.data.domain)

            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteDomain({ commit, state }, domain) {
      const oldDomainId = domain._id

      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/domains/${domain._id}`)
          .then(response => {
            commit(
              'SET_DOMAINS',
              state.domains.filter(item => item._id !== oldDomainId),
            )

            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-empty-pattern
    saveDomain({}, form) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/api/domains/${form._id}`, {
            name: form.name,
            url: form.url,
            email: form.email,
            smtp: form.smtp,
            imap: form.imap,
            status: form.status,
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-empty-pattern
    createDomain({}, form) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/domains', {
            name: form.name,
            url: form.url,
            email: form.email,
            smtp: form.smtp,
            imap: form.imap,
            status: form.status,
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchTrips({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/trips', {
            params: payload,
          })
          .then(response => {
            commit('SET_TRIPS', response.data)

            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchTrip({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/trips/${payload}`)
          .then(response => {
            commit('SET_TRIP', response.data)

            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteTrip({ commit, state }, trip) {
      const oldTripId = trip._id

      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/trips/${trip._id}`)
          .then(response => {
            commit(
              'SET_TRIPS',
              state.trips.trips.filter(item => item._id !== oldTripId),
            )

            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    duplicateTrip({ dispatch }, tripId) {
      return axios
        .post(`/api/trips/duplicate/${tripId}`)
        .then(response => {
          dispatch('fetchCustomTrips')

          return response
        })
    },
    batchDeleteTrips({ commit, state }, trips) {
      const oldTripIds = trips

      return new Promise((resolve, reject) => {
        axios
          .post('/api/trips/batch-delete', {
            param: oldTripIds,
          })
          .then(response => {
            commit(
              'SET_TRIPS',
              state.trips.trips.filter(item => Object.values(oldTripIds).includes(item._id)),
            )
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-empty-pattern
    saveTrip({}, trip) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/api/trips/${trip._id}`, {
            email: trip.email,
            vendor: trip.vendor._id,
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchCustomTrips({ commit, state }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/custom-trips', {
            token: state.loggedUser.wptoken,
          })
          .then(response => {
            commit('SET_CUSTOM_TRIPS', response.data.customTrips)

            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchCustomTrip({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/custom-trips/${payload}`, {
            token: state.loggedUser.wptoken,
          })
          .then(response => {
            commit('SET_CUSTOM_TRIP', response.data.trip)
            commit('SET_AVAILABLE_USERS', response.data.users)

            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteCustomTrip({ dispatch, state }, customTrip) {
      return axios
        .delete(`/api/custom-trips/${customTrip.id}`, {
          data: {
            token: state.loggedUser.wptoken,
          },
        })
        .then(response => {
          dispatch('fetchCustomTrips')

          return response
        })
    },
    // eslint-disable-next-line no-empty-pattern
    selectVendorCustomTrip({ state }, vendor) {
      return axios
        .post(`/api/custom-trips/select-vendor/${state.customTrip.trip.offer_id}`, {
          vendorId: vendor.user_id,
          token: state.loggedUser.wptoken,
        })
        .then(response => response)
        .catch(error => error)
    },
    duplicateCustomTrip({ dispatch, state }, customTripId) {
      return axios
        .post(`/api/custom-trips/duplicate/${customTripId}`, {
          token: state.loggedUser.wptoken,
        })
        .then(response => {
          dispatch('fetchCustomTrips')

          return response
        })
    },
  },
}
